import React from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import { Page, LayoutSingleColumn } from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import { useConfiguration } from '../../context/configurationContext';
import SectionHero from './SectionHero/SectionHero';
import SectionTopArtists from './SectionTopArtists/SectionTopArtists';
import SectionHowItWorks from './SectionHowItWorks/SectionHowItWorks';
import SectionFindArtists from './SectionFindArtists/SectionFindArtists';
import SectionWhyChooseUs from './SectionWhyChooseUs/SectionWhyChooseUs';
import SectionExploreRegion from './SectionExploreRegion/SectionExploreRegion';
import SectionBlogs from './SectionBlogs/SectionBlogs';
import SectionExpertHelp from './SectionExpertHelp/SectionExpertHelp';

import css from './LandingPage.module.css';

export const LandingPageComponent = props => {
  const { history, intl, location, scrollingDisabled, onManageDisableScrolling } = props;

  const config = useConfiguration();

  const { branding } = config;

  const { facebookImage, twitterImage } = branding || {};

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'LandingPage.schemaTitle' },
    { marketplaceName: siteTitle }
  );
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });

  const schemaImage = facebookImage?.startsWith('https://')
    ? facebookImage
    : `${config.marketplaceRootURL}${facebookImage}`;

  const twitterSchemaImage = twitterImage?.startsWith('https://')
    ? twitterImage
    : `${config.marketplaceRootURL}${twitterImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      openGraphType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: schemaImage, width: 1200, height: 630 }]}
      twitterImages={[{ url: twitterSchemaImage, width: 600, height: 314 }]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn
        topbar={<TopbarContainer currentPage="LandingPage" />}
        footer={<FooterContainer />}
      >
        <div className={css.container}>
          <div>
            <SectionHero
              className={css.hero}
              history={history}
              location={location}
              onManageDisableScrolling={onManageDisableScrolling}
            />
          </div>
          <ul className={css.sections}>
            <li className={css.section}>
              <SectionTopArtists className={css.sectionRoot} />
            </li>

            <li className={css.section}>
              <SectionFindArtists className={css.sectionRoot} />
            </li>

            <li className={css.section}>
              <SectionExpertHelp className={css.sectionRoot} />
            </li>
            <li className={css.section}>
              <SectionExploreRegion className={css.sectionRoot} />
            </li>
            <li className={css.section}>
              <SectionHowItWorks className={css.sectionRoot} />
            </li>

            <li className={css.section}>
              <SectionWhyChooseUs className={css.sectionRoot} />
            </li>

            <li className={css.section}>
              <SectionBlogs className={css.sectionRoot} />
            </li>
          </ul>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
