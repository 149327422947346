import React from 'react';

import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import classNames from 'classnames';
import { H2, NamedLink, BlogList } from '../../../components';
import { useSelector } from 'react-redux';

import css from './SectionBlogs.module.css';

const SectionBlogs = props => {
  const { className } = props;

  const { getBlogsInProgress, getBlogsError, blogs } = useSelector(state => state.LandingPage);

  const intl = useIntl();

  const classes = classNames(css.root, className);

  return (
    <div className={classes}>
      <H2 className={css.title}>
        <FormattedMessage id="General.blog" />
      </H2>

      <div className={css.desc}>{intl.formatMessage({ id: 'SectionBlogs.desc' })}</div>

      <BlogList
        blogs={blogs}
        inProgress={getBlogsInProgress}
        error={getBlogsError}
        className={css.blogs}
      />

      <NamedLink name="ClientBlogsPage" className={css.cta}>
        {`${intl.formatMessage({ id: 'General.exploreFurther' })} ››`}
      </NamedLink>
    </div>
  );
};

export default SectionBlogs;
