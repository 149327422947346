import React from 'react';

import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import classNames from 'classnames';
import { H2, NamedLink } from '../../../components';
import { lazyLoadWithDimensions } from '../../../util/uiHelpers';

import Creator1Image from '../../AboutUsPage/images/jack.jpeg';
import Creator2Image from '../../AboutUsPage/images/michael.jpeg';

import css from './SectionExpertHelp.module.css';

const Image = props => {
  const { alt, ...rest } = props;

  return <img {...rest} alt={alt} />;
};

const LazyImage = lazyLoadWithDimensions(Image, {
  loadAfterInitialRendering: 3000,
});

const SectionExpertHelp = props => {
  const { className } = props;

  const intl = useIntl();

  const classes = classNames(css.root, className);

  const ctas = (
    <div className={css.ctas}>
      <NamedLink name="ClientBlogsPage" className={css.secondaryCta}>
        {intl.formatMessage({ id: 'General.clientBlogs' })}
      </NamedLink>

      <NamedLink name="ContactUsPage" className={css.primaryCta}>
        {intl.formatMessage({ id: 'ContactUsPage.title' })}
      </NamedLink>
    </div>
  );

  return (
    <div className={classes}>
      <div>
        <H2 className={css.title}>
          <FormattedMessage id="SectionExpertHelp.title" />
        </H2>
        <div className={css.content}>
          <div className={css.images}>
            <LazyImage src={Creator1Image} alt="" />
          </div>
          <div className={css.text}>
            <div className={css.desc}>
              {intl.formatMessage(
                { id: 'SectionExpertHelp.desc' },
                { span: text => <span className={css.boldText}>{text}</span> }
              )}
            </div>

            <div className={css.desktopCtas}>{ctas}</div>
          </div>

          <div className={css.images}>
            <LazyImage src={Creator2Image} alt="" />
          </div>
          <div className={css.mobileCtas}>{ctas}</div>
        </div>
      </div>
      <div className={css.gradient} />
    </div>
  );
};

export default SectionExpertHelp;
