import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { number, shape, string } from 'prop-types';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink, H1 } from '../../../components';
import { createResourceLocatorString } from '../../../util/routes';
import { withViewport } from '../../../util/uiHelpers';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { useConfiguration } from '../../../context/configurationContext';
import defaultLocations from '../../../config/configDefaultLocationSearches';
import TopbarSearchForm from '../../TopbarContainer/Topbar/TopbarSearchForm/TopbarSearchForm';
import { parseSearchFormValuesToSearch } from '../../../util/search';

import css from './SectionHero.module.css';
import { stringify } from '../../../util/urlHelpers';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const SectionHero = props => {
  const { rootClassName, className, history, viewport } = props;

  const [mounted, setMounted] = useState(false);

  const routeConfiguration = useRouteConfiguration();
  const config = useConfiguration();
  const intl = useIntl();

  const classes = classNames(rootClassName || css.root, className);

  const isMobileLayout = useMemo(() => viewport.width < MAX_MOBILE_SCREEN_WIDTH, [viewport.width]);

  const handleSubmit = useCallback(
    formValues => {
      const formValuesAsParams = parseSearchFormValuesToSearch(formValues);

      history.push(
        createResourceLocatorString('SearchPage', routeConfiguration, {}, formValuesAsParams)
      );
    },
    [history, routeConfiguration]
  );

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div id="SectionHeroFormContainer" className={css.titleAndForm}>
          <H1 className={classNames(css.heroMainTitle, { [css.animation]: mounted })}>
            <FormattedMessage id="SectionHero.title" />
            <span className={css.dot}>.</span>
          </H1>

          <div className={classNames(css.heroSubTitle, { [css.animation]: mounted })}>
            <FormattedMessage id="SectionHero.desc" />
          </div>

          <TopbarSearchForm
            onSubmit={handleSubmit}
            isMobile={isMobileLayout}
            appConfig={config}
            className={classNames(css.searchForm, { [css.animation]: mounted })}
          />

          <div className={classNames(css.citiesDesc, { [css.animation]: mounted })}>
            {intl.formatMessage({ id: 'SectionHero.citiesDesc' })}
          </div>
        </div>

        <div className={classNames(css.cities, { [css.animation]: mounted })}>
          {defaultLocations.map(({ id, predictionPlace, postCodes }) => {
            const city = predictionPlace.address.split(',')[0];

            return (
              <NamedLink
                key={id}
                name="SearchPage"
                to={{
                  search: stringify({
                    address: predictionPlace.address,
                    pub_serviceableLocations: postCodes,
                  }),
                }}
              >
                {city}
              </NamedLink>
            );
          })}
        </div>
      </div>

      <div className={css.gradient} />
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

export default withViewport(SectionHero);
