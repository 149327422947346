import React, { useMemo } from 'react';

import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import classNames from 'classnames';
import { H2, NamedLink, AspectRatioWrapper } from '../../../components';
import { useConfiguration } from '../../../context/configurationContext';
import { lazyLoadWithDimensions } from '../../../util/uiHelpers';

import weddingImage from './images/wedding.png';
import djImage from './images/dj.png';
import classicalImage from './images/classical.png';
import jazzImage from './images/jazz.png';

import css from './SectionFindArtists.module.css';
import { stringify } from '../../../util/urlHelpers';

const Image = props => {
  const { alt, ...rest } = props;

  return <img {...rest} alt={alt} />;
};

const LazyImage = lazyLoadWithDimensions(Image, {
  loadAfterInitialRendering: 3000,
});

const SectionFindArtists = props => {
  const { className } = props;

  const intl = useIntl();
  const config = useConfiguration();

  const classes = classNames(css.root, className);

  const categoriesOptions =
    config.listing.listingFields.find(f => f.key === 'bandType')?.enumOptions || [];

  const categories = useMemo(
    () => [
      {
        category: 'weddingAndFunctionBands',
        imgSrc: weddingImage,
      },
      {
        category: 'djAndLiveMusicians',
        imgSrc: djImage,
      },
      {
        category: 'stringQuartetsAndClassical',
        imgSrc: classicalImage,
      },
      {
        category: 'jazzAndSwingBands',
        imgSrc: jazzImage,
      },
    ],
    []
  );

  return (
    <div className={classes}>
      <H2 className={css.title}>
        <FormattedMessage id="SectionFindArtists.title" />
      </H2>

      <div className={css.desc}>{intl.formatMessage({ id: 'SectionFindArtists.desc' })}</div>

      <div className={css.categories}>
        {categories.map((cat, i) => {
          const { imgSrc, category } = cat;

          const title = categoriesOptions.find(cat => cat.option === category)?.label;

          return (
            <NamedLink
              key={i}
              name="SearchPage"
              to={{
                search: stringify({
                  pub_bandType: [category],
                }),
              }}
            >
              <AspectRatioWrapper width={16} height={9}>
                <LazyImage src={imgSrc} alt={title} />
              </AspectRatioWrapper>
              <div className={css.categoryTitle}>
                <div>{title}</div>
              </div>
            </NamedLink>
          );
        })}
      </div>
    </div>
  );
};

export default SectionFindArtists;
