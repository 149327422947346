import React from 'react';

import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import classNames from 'classnames';
import { H2, Carousel, NamedLink, ListingCard, IconSpinner } from '../../../components';
import { useSelector } from 'react-redux';

import css from './SectionTopArtists.module.css';

const SectionTopArtists = props => {
  const { className } = props;

  const {
    queryPopularListingsInProgress,
    queryPopularListingsError,
    popularListings,
  } = useSelector(state => state.LandingPage);

  const intl = useIntl();

  const classes = classNames(css.root, className);

  const cardRenderSizes = isMapVariant => {
    return [
      '(max-width: 549px) 100vw',
      '(max-width: 767px) 50vw',
      `(max-width: 1439px) 26vw`,
      `(max-width: 1920px) 18vw`,
      `14vw`,
    ].join(', ');
  };

  return (
    <div className={classes}>
      <H2 className={css.title}>
        <FormattedMessage id="SectionTopArtists.title" />
      </H2>

      <div className={css.desc}>{intl.formatMessage({ id: 'SectionTopArtists.desc' })}</div>

      {queryPopularListingsError ? (
        <div className={css.error}>
          <FormattedMessage id="General.error" />
        </div>
      ) : null}

      {queryPopularListingsInProgress ? (
        <IconSpinner />
      ) : (
        <Carousel numColumns={4} id="top-artists">
          {popularListings?.map(l => (
            <ListingCard
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes()}
              showExtendedCategories
            />
          ))}
        </Carousel>
      )}

      <NamedLink name="SearchPage" className={css.cta}>
        {`${intl.formatMessage({ id: 'General.exploreFurther' })} ››`}
      </NamedLink>
    </div>
  );
};

export default SectionTopArtists;
