import React, { useMemo } from 'react';

import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import classNames from 'classnames';
import { H2, H3, NamedLink } from '../../../components';
import { useConfiguration } from '../../../context/configurationContext';
import { lazyLoadWithDimensions } from '../../../util/uiHelpers';
import searchImage from './images/search.png';
import connectImage from './images/connect.png';
import partyImage from './images/party.png';

import css from './SectionHowItWorks.module.css';

const Image = props => {
  const { alt, ...rest } = props;

  return <img {...rest} alt={alt} />;
};

const LazyImage = lazyLoadWithDimensions(Image);

const SectionHowItWorks = props => {
  const { className } = props;

  const intl = useIntl();
  const config = useConfiguration();

  const classes = classNames(css.root, className);

  const columns = useMemo(
    () => [
      {
        iconSrc: searchImage,
        title: intl.formatMessage({ id: 'General.search' }),
        items: [
          intl.formatMessage({ id: 'SectionHowItWorks.itemSearch1' }),
          intl.formatMessage({ id: 'SectionHowItWorks.itemSearch2' }),
          intl.formatMessage({ id: 'SectionHowItWorks.itemSearch3' }),
        ],
      },
      {
        iconSrc: connectImage,
        title: intl.formatMessage({ id: 'General.connect' }),
        items: [
          intl.formatMessage({ id: 'SectionHowItWorks.itemConnect1' }),
          intl.formatMessage({ id: 'SectionHowItWorks.itemConnect2' }),
          intl.formatMessage({ id: 'SectionHowItWorks.itemConnect3' }),
        ],
      },
      {
        iconSrc: partyImage,
        title: intl.formatMessage({ id: 'General.party' }),
        items: [
          intl.formatMessage({ id: 'SectionHowItWorks.itemParty1' }),
          intl.formatMessage({ id: 'SectionHowItWorks.itemParty2' }),
        ],
      },
    ],
    [intl]
  );

  return (
    <div className={classes}>
      <H2 className={css.title}>
        <FormattedMessage
          id="SectionHowItWorks.title"
          values={{
            marketplaceName: <span className={css.marketpaceName}>{config.marketplaceName}</span>,
          }}
        />
      </H2>

      <div className={css.desc}>{intl.formatMessage({ id: 'SectionHowItWorks.desc' })}</div>

      <div className={css.columns}>
        {columns.map(({ iconSrc, items, title }, index) => {
          return (
            <div key={index}>
              <div>
                <LazyImage src={iconSrc} alt="" />
              </div>
              <H3> {`${index + 1}. ${title}`}</H3>
              <ul>
                {items.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>

      <NamedLink name="HowItWorksPage" className={css.cta}>
        <FormattedMessage id="SectionHowItWorks.cta" />
      </NamedLink>
    </div>
  );
};

export default SectionHowItWorks;
