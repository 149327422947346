import React, { useMemo } from 'react';

import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import classNames from 'classnames';
import { H2, NamedLink, AspectRatioWrapper } from '../../../components';
import { useConfiguration } from '../../../context/configurationContext';
import { lazyLoadWithDimensions } from '../../../util/uiHelpers';

import aberdeen from './images/aberdeen-and north-east.jpg';
import eastOfEngland from './images/east-of-england.jpg';
import glasgow from './images/glasgow.png';
import highlands from './images/highlands-and-islands.jpg';
import london from './images/london.png';
import newcastle from './images/newcastle.png';
import northWest from './images/north-west.jpg';
import nottingham from './images/nottingham.png';
import scotlandSouth from './images/scotland-south.jpg';
import southEast from './images/south-east.jpg';
import southWest from './images/south-west.jpg';
import tayside from './images/tayside-central-and-fife.jpg';
import wales from './images/wales.jpg';
import westMidlands from './images/west-midlands.jpg';
import yorkshire from './images/yorkshire-and-the-humber.jpg';
import edinburgh from './images/edinburgh.png';

import { stringify } from '../../../util/urlHelpers';

import css from './SectionExploreRegion.module.css';

const Image = props => {
  const { alt, ...rest } = props;

  return <img {...rest} alt={alt} />;
};

const LazyImage = lazyLoadWithDimensions(Image, {
  loadAfterInitialRendering: 3000,
});

const SectionExploreRegion = props => {
  const { className } = props;

  const intl = useIntl();
  const config = useConfiguration();

  const classes = classNames(css.root, className);

  const locationOptions = useMemo(
    () =>
      config.listing.listingFields.find(f => f.key === 'serviceableLocations')?.enumOptions || [],
    [config.listing.listingFields]
  );

  const regions = useMemo(
    () =>
      locationOptions.filter(region => {
        const { option } = region;

        return option !== 'all';
      }),

    [locationOptions]
  );

  // IMPORTANT: Keep up to date with configListing.js -> serviceableLocations
  const regionLabelToImage = useMemo(
    () => ({
      'Aberdeen and North East': aberdeen,
      'East Midlands': nottingham,
      'East of England': eastOfEngland,
      'Edinburgh and Lothians': edinburgh,
      'Glasgow and Strathclyde': glasgow,
      'Highland and Islands': highlands,
      'London & Greater London': london,
      'North East': newcastle,
      'North West': northWest,
      'Scotland South': scotlandSouth,
      'South East': southEast,
      'South West': southWest,
      'Tayside, Central and Fife': tayside,
      'Yorkshire and the Humber': yorkshire,
      'West Midlands': westMidlands,
      Wales: wales,
    }),
    []
  );

  return (
    <div className={classes}>
      <H2 className={css.title}>
        <FormattedMessage id="SectionExploreRegion.title" />
      </H2>

      <div className={css.desc}>{intl.formatMessage({ id: 'SectionExploreRegion.desc' })}</div>

      <div className={css.regions}>
        {regions.map((region, i) => {
          const { label, option } = region;

          return (
            <NamedLink
              key={i}
              name="SearchPage"
              to={{
                search: stringify({
                  pub_serviceableLocations: option,
                }),
              }}
            >
              <AspectRatioWrapper width={16} height={9}>
                <LazyImage src={regionLabelToImage[label]} alt={label} />
              </AspectRatioWrapper>
              <div className={css.regionName}>
                <div>{label}</div>
              </div>
            </NamedLink>
          );
        })}
      </div>
    </div>
  );
};

export default SectionExploreRegion;
